<template>
    <div class="watermark"></div>
  </template>
  
  <script>
  export default {
    name: 'Watermark'
  }
  </script>
  
  <style scoped>
  .watermark {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("/logo-kp-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 28%;
    opacity: 0.1;
    z-index: 9999;
    pointer-events: none;
    }
  </style>
  