const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Home",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "Apps",
    route: "/",
    pages: [
      // {
      //   sectionTitle: "Master",
      //   route: "/master",
      //   svgIcon: "media/icons/duotune/general/gen022.svg",
      //   fontIcon: "bi-archive",
      //   sub: [
      //     {
      //       heading: "Region",
      //       route: "/#",
      //     },
      //     {
      //       heading: "User Vendor",
      //       route: "/master/vendor",
      //     },
      //   ],
      // },
      {
        heading: "List Purchase Order",
        route: "/purchase-order",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-briefcase",
      },
      // {
      //   sectionTitle: "Example",
      //   route: "/example",
      //   svgIcon: "media/icons/duotune/general/gen022.svg",
      //   fontIcon: "bi-archive",
      //   sub: [
      //     {
      //       heading: "Example",
      //       route: "/example/user",
      //     },
          
      //   ],
      // },
      
      // {
      //   sectionTitle: "Procurement",
      //   route: "/procurement",
      //   svgIcon: "media/icons/duotune/general/gen022.svg",
      //   fontIcon: "bi-archive",
      //   sub: [
      //     {
      //       heading: "Purchase Order",
      //       route: "/#",
      //     }
      //   ],
      // },
    ],
  },
  
];

export default DocMenuConfig;
